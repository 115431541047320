

.btn-chocalate {
  background: #200d0f;
  color: #fff;
}

.mint-box{
border: none!important;
    box-shadow: 0 0 15px rgb(240 233 233 / 79%);
    background: transparent!important;
}

/* accordion css */
.accordion__panel {
  text-align: left;
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

.bg-light-grey h2 {
  color: #fff;
}
.accordion__button {
  background: linear-gradient(313deg, #19cc00, #22561b);
  /* background: linear-gradient(
1deg, rgb(0 168 255) 0%, rgb(0 0 0) 100%); */
  color: #fff!important;
  cursor: pointer;
  border: 1px solid #1ab705!important;
  padding: 18px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  border-radius: 10px;
  border: none;
  margin: 10px 0px;
}
.accordion__button:focus {
  outline: rgb(30 141 15) auto 2px;
  outline-offset: 2px;
 
}
.accordion__button:before {
  display: inline-block;
  content: '';
  float: right;
  height: 10px;
  position: relative;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
  top: 8px;
}
/* ============================= */
.bg-light-grey {
  /* background: linear-gradient(
174deg, #181818, #080808); */
  /* box-shadow: 0px 5px 10px #000; */
  min-height: 400px;
  border-radius: 20px;
  padding: 20px 20px 0px 0px;
  /* border: 1px solid #c3921e; */
}
.banner-btns.d-flex.flex-wrap span {
  font-size: 16px;
  font-weight: 700;
}
/* -------------------------------------- */
.wallet-box {
  position: absolute;
  z-index: 0;
  display: inline-block;
  width: 32px;
  top: 19px;
  color: #fff!important;
  height: 2rem;
  background: #222222;
  box-shadow: 0px 5px 6px #19cc00;
  border-radius: 50%!important;
  border: -2px solid #ccc;
  line-height: 2rem;
  text-align: center;
  color: inherit;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -ms-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  margin-right: 5px;
  margin-left: 5px;
  right: -47px;
  border-radius: 4px;
  cursor: pointer;
  /* padding: 3px 20px; */
  background: #000;
  /* min-height: 37px; */
  margin-top: 0px;
  line-height: 27px!important;
  bottom: 14px;
}
img.wallet_icon {
  margin-top: -1px;
}
/* ----------right modal----------- */
.slide-pane {
  display: flex;
  flex-direction: column;
  background: #000!important;
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s;
  will-change: transform;
}
.wallet_address {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  padding: 20px 0px;
  /* background: linear-gradient(
360deg
, #4a2c22, #986b4f); */
}
.wallet_address label {
  /* text-align: left; */
  font-size: 16px;
  font-weight: 600;
}
.show_balance p {
  font-size: 18px;
  font-weight: 600;
  margin: 10px;
  padding: 6px 0px;
  text-align: center;
  border: 1px solid;
}
.show_balance h4 {
  font-size: 16px;
  margin: 20px;
}
button.btn.btn-primary.add_fund_btn {
  box-shadow: 0px 4px 10px #00000057;
  background: linear-gradient(45deg, #19cc00, #126a05)!important;
  border: 0;
}
.show_balance {
  text-align: center;
}

.meet_our .swiper-container {
  z-index: 0!important;
}
.smoke_gif {
  width: 200px;
  margin:12px auto;
}
.smoke_gif img {
  border-radius: 10px;
}
.meet_our .slick-prev {
  left: 0px!important;
}
.meet_our .slick-next {
  right: 0px;
}
.meet_our .slick-slider .slick-track,.meet_our .slick-slider .slick-list {
  overflow-x: hidden;

}